<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" />
          <span>基础设置</span>
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :offset="8" :span="12">
        <el-form
          ref="detail"
          label-position="right"
          label-width="180px"
          :rules="rules"
          :model="detail"
        >
          <el-form-item label="名称:" prop="name">
            <el-input v-model="detail.name" class="w300" placeholder="请输入名称" clearable />
          </el-form-item>

          <el-form-item label="所属类型:" prop="category_id">
            <el-select
              v-model="detail.category_id"
              style="width: 300px"
              placeholder="请选择所属类型"
              clearable
              :disabled="changeType"
            >
              <el-option
                v-for="item in category"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" class="mainBtn" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  settingCategory,
  settingInfo,
  createSetting,
  updateSetting
} from "@/api/client";
export default {
  props: {
    id: [String, Number],

    edit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      detail: {
        name: "",
        category_id: "",
        status: 1
      },
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        category_id: [
          { required: true, message: "所属类型不能为空", trigger: "change" }
        ]
      },
      category: [],
      changeType:false
    };
  },
  created() {
    this.getSettingCategory();
    
    if(this.id){
      this.changeType=true

    }else{
      this.changeType=false
    }
  },
  methods: {
    // 提交
    submitForm() {
      this.$refs.detail.validate(valid => {
        if (valid) {
          if (this.id) {
            updateSetting({ ...this.detail, id: this.id }).then((res) => {
              this.$message({
                type: "success",
                message: res.msg
              });
              this.$router.go(-1);
            });
          } else {
            createSetting({...this.detail,type:1}).then((res) => {
              this.$message({
                type: "success",
                message: res.msg
              });
              this.$router.go(-1);
            });
          }
        }
      });
    },
    // 获取数据
    getSettingCategory() {
      settingCategory().then(res => {
        this.category = res.data;
      });

      if (this.id) {
        settingInfo({ id: this.id }).then(res => {
          this.detail.category_id = res.data.info.category_id;
          this.detail.name = res.data.info.name;
          this.detail.status = res.data.info.status;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  height: 80vh;
  background: #fff;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    min-width: 160px;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      white-space: nowrap;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    height: 100%;
    .btn {
      margin: 20px 0 50px 80px;
    }
    .mainBtn {
      @include successBtn;
      margin-top: 20px;
    }
    .phoneTip {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
